import { useState, createContext, useContext } from 'react';
import { createClient, dedupExchange, fetchExchange, Provider } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { API_BASE } from '../constants';
import { cacheExchange } from '@urql/exchange-graphcache';
import { relayPagination } from '@urql/exchange-graphcache/extras';
import { authExchange } from '@urql/exchange-auth';
import { makeOperation } from '@urql/core';
import { refreshToken } from 'api/auth';
import { useAuth } from './AuthProvider';
import { useDeepCompareEffect } from 'react-use';

const ClientContext = createContext();

export const useClient = () => {
  const context = useContext(ClientContext);

  if (context === undefined) {
    throw new Error(`useClient must be used within an ClientProvider`);
  }

  return context;
};

const makeClient = () =>
  createClient({
    url: `${API_BASE}/graphql`,
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      cacheExchange({
        resolvers: {
          Query: {
            allIdentities: relayPagination(),
          },
        },
      }),
      authExchange({
        addAuthToOperation: ({ authState, operation }) => {
          // the token isn't in the auth state, return the operation without changes
          if (!Boolean(authState?.token)) {
            return operation;
          }

          // fetchOptions can be a function but you can simplify this based on usage
          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.token}`,
              },
            },
          });
        },

        willAuthError: ({ authState }) => {
          if (!authState) {
            return true;
          }

          return false;
        },

        didAuthError: ({ error }) => {
          // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
          return error?.response?.statusText === 'Unauthorized';
        },

        getAuth: async ({ authState }) => {
          // for initial launch, fetch the auth state from storage (local storage, async storage etc)
          if (!authState) {
            const token = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');

            if (Boolean(token) && Boolean(refreshToken)) {
              return { token, refreshToken };
            }
            return null;
          }

          // the following code gets executed when an auth error has occurred
          // we should refresh the token if possible and return a new auth state
          // If refresh fails, we should log out
          try {
            const { data } = await refreshToken({
              refreshToken: authState?.refreshToken,
            });

            if (Boolean(data?.accessToken)) {
              localStorage.setItem('accessToken', data.accessToken);
              localStorage.setItem('refreshToken', data.refreshToken);

              return {
                token: data.accessToken,
                refreshToken: data.refreshToken,
              };
            }
          } catch (err) {}

          // otherwise, if refresh fails, log clear storage and log out
          localStorage.clear();

          return null;
        },
      }),
      fetchExchange,
    ],
  });

const ClientProvider = ({ children }) => {
  const [client, setClient] = useState(makeClient());
  const { isLoggedIn } = useAuth();

  const resetClient = () => {
    setClient(makeClient());
  };

  useDeepCompareEffect(() => {
    resetClient();
  }, [{ isLoggedIn }]);

  return (
    <ClientContext.Provider
      value={{
        resetClient,
        client,
      }}
    >
      <Provider value={client}>{children}</Provider>
    </ClientContext.Provider>
  );
};

export default ClientProvider;
