import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { IcError, IcInfo, IcSuccess } from '@tymate/sergic/components/icons';
import { ui } from 'ui';

const ToastBodyWrapper = styled(Stack)`
  ${({ variant, theme }) => {
    switch (variant) {
      case 'error':
        return `
          background-color: ${ui('backgroundError')};
        `;
      case 'success':
        return `
          background-color: ${ui('backgroundSuccess')};
          color:${ui('success')}}
        `;
      case 'warning':
        return `
        background-color: ${ui('backgroundWarning')};
        `;
      default:
        return '';
    }
  }}
  ${({ variant, theme }) =>
    Boolean(variant) &&
    `
      color: ${theme?.[variant]};
      border: 1px solid ${theme?.[variant]};
    `}
    height: 35px;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const ToastBody = ({ variant, children }) => (
  <ToastBodyWrapper gutterSize={0.5} alignY="center" variant={variant}>
    <Stack>
      {variant === 'success' && <IcSuccess size={20} />}
      {variant === 'warning' && <IcInfo size={20} />}
      {variant === 'error' && <IcError size={20} />}
    </Stack>
    <div>{children}</div>
  </ToastBodyWrapper>
);

export default ToastBody;
