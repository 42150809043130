import Icon from 'react-icon-base';

const IcSpeaker = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path d="M13.4046 20.8159L10.718 2.51123L6.45234 8.16315L1.51038 8.92259L2.8202 17.4461L7.76216 16.6866L13.4046 20.8159Z" fill="#5C7592"/>
    <path d="M20.9109 15.5777L15.6277 14.0132L15.171 14.9843L19.7638 17.9882L20.9109 15.5777Z" fill="#5C7592"/>
    <path d="M21.4096 11.9149L20.9131 8.72701L15.3962 10.4742L15.6061 11.8401L21.4096 11.9149Z" fill="#5C7592"/>
    <path d="M18.5686 4.72443L17.0123 3.08778L13.4047 7.33064L14.3865 8.23363L18.5686 4.72443Z" fill="#5C7592"/>
  </Icon>
);

export default IcSpeaker;
