import { createContext, useContext } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.minimal.css';
import 'animate.css';

const Slide = cssTransition({
  enter: 'animate__slideInUp',
  exit: 'animate__slideOutDown',
  duration: 150,
  appendPosition: false,
  collapse: true,
  collapseDuration: 150,
});

const  ToastContext = createContext();
export const useToast = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error(`useToast must be used within an ToastProvider`);
  }

  return context;
};

const ToastStyles = createGlobalStyle`
  .Toastify__toast-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483647;
    padding: ${({ theme }) => theme.spacing()};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  }

  .Toastify__toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    max-width: 100%;
    background-color: #ffffff;
    border-radius: 999em;
    box-shadow: 0 4px 8px ${({ theme }) => theme.separatorLight};
  }

  .Toastify__toast-body {
    flex: 1;
  }

  .Toastify__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme }) => theme.spacing(1.25)};
    height: ${({ theme }) => theme.spacing(1.25)};
    padding: 0;
    border: 0;
    cursor: pointer;

    svg {
      width: 100%;
      display: block;
      fill: ${({ theme }) => theme.textLight};
    }
  }
`;

const ToastProvider = ({ children }) => {
  return (
    <ToastContext.Provider
      value={{
        sendToast: toast,
      }}
    >
      {children}
      <ToastStyles />
      <ToastContainer
        closeButton={false}
        position="bottom-center"
        transition={Slide} />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
