import Icon from 'react-icon-base';

const IcError = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 11.5H13V6.5H11V11.5ZM12 15.5C12.2833 15.5 12.521 15.404 12.713 15.212C12.9043 15.0207 13 14.7833 13 14.5C13 14.2167 12.9043 13.979 12.713 13.787C12.521 13.5957 12.2833 13.5 12 13.5C11.7167 13.5 11.4793 13.5957 11.288 13.787C11.096 13.979 11 14.2167 11 14.5C11 14.7833 11.096 15.0207 11.288 15.212C11.4793 15.404 11.7167 15.5 12 15.5ZM12 22.5C9.31667 20.2167 7.31267 18.0957 5.988 16.137C4.66267 14.179 4 12.3667 4 10.7C4 8.2 4.80433 6.20833 6.413 4.725C8.021 3.24167 9.88333 2.5 12 2.5C14.1167 2.5 15.979 3.24167 17.587 4.725C19.1957 6.20833 20 8.2 20 10.7C20 12.3667 19.3377 14.179 18.013 16.137C16.6877 18.0957 14.6833 20.2167 12 22.5Z" fill="#D7263D"/>
    </svg>
  </Icon>
);

export default IcError;
