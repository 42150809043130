import { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Router } from 'react-router-dom';
import { MargaretProvider } from '@tymate/margaret';
import AuthProvider from 'containers/AuthProvider';
import ClientProvider from 'containers/ClientProvider';
import ToastProvider from 'containers/ToastProvider';
import { theme } from './ui';
import { SENTRY_DSN, ENVIRONMENT } from './constants';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  release: 'sergic-integration@' + process.env.npm_package_version,
  tracesSampleRate: 0.1,
  environment: ENVIRONMENT,
});

const Loading = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
    }}
  />
);

const Providers = ({ children, history }) => (
  <Suspense fallback={<Loading />}>
    <Sentry.ErrorBoundary fallback={<div>Une erreur est survenue</div>}>
      <Router history={history}>
        <MargaretProvider theme={theme}>
          <ToastProvider>
            <AuthProvider>
              <ClientProvider>{children}</ClientProvider>
            </AuthProvider>
          </ToastProvider>
        </MargaretProvider>
      </Router>
    </Sentry.ErrorBoundary>
  </Suspense>
);

export default Providers;
