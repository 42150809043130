import Icon from 'react-icon-base';

const IcFile = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 22.0002C5.45 22.0002 4.97917 21.8044 4.5875 21.4127C4.19583 21.0211 4 20.5502 4 20.0002V4.00024C4 3.45024 4.19583 2.97941 4.5875 2.58774C4.97917 2.19608 5.45 2.00024 6 2.00024H14L20 8.00024V20.0002C20 20.5502 19.8042 21.0211 19.4125 21.4127C19.0208 21.8044 18.55 22.0002 18 22.0002H6ZM13 9.00024H18L13 4.00024V9.00024Z" fill="#002C60"/>
    </svg>
  </Icon>
);

export default IcFile;
