import Icon from 'react-icon-base';

const Success = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.37207 21C7.7054 21 6.28874 20.4167 5.12207 19.25C3.9554 18.0833 3.37207 16.6667 3.37207 15V9C3.37207 7.33333 3.9554 5.91667 5.12207 4.75C6.28874 3.58333 7.7054 3 9.37207 3H15.3721C17.0387 3 18.4554 3.58333 19.6221 4.75C20.7887 5.91667 21.3721 7.33333 21.3721 9V15C21.3721 16.6667 20.7887 18.0833 19.6221 19.25C18.4554 20.4167 17.0387 21 15.3721 21H9.37207ZM11.3721 16L17.3721 10L15.9721 8.6L11.3721 13.2L9.17207 11L7.77207 12.4L11.3721 16Z" fill="#0C8484"/>
    </svg>
  </Icon>
);

export default Success;
