export const API_BASE = process.env.REACT_APP_API_BASE;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const API_SCOPE = process.env.REACT_APP_API_SCOPE;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const CLIENT_APP_URL = process.env.REACT_APP_CLIENT_APP_URL;

export const SERGIC_OFFER = 'DIR';

export const MAINTENANCE_ALERT = process.env.REACT_APP_MAINTENANCE_ALERT;
