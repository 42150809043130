import {API_SCOPE, CLIENT_ID} from '../constants';
import { post } from 'api';

export const login = ({ email, password }) =>
  post(`/v1/oauth/token`, {
    username: email,
    password,
    client_id: CLIENT_ID,
    grant_type: 'password',
    scope: API_SCOPE,
  });

export const checkMail = async ({ email }) =>
  post(`/v1/users/mail_check`, {
    client_id: CLIENT_ID,
    user: { email },
  });

export const sendForgottenEmailPassword = ({ email }) =>
  post('/v1/users/reset_password', {
    user: { email, sergic_offer: 'DIR' },
    client_id: CLIENT_ID,
  });

export const refreshToken = ({ refreshToken }) =>
  post(`/v1/oauth/token`, {
    refreshToken,
    clientId: CLIENT_ID,
    grantType: 'refresh_token',
    scope: API_SCOPE,
  });
