import { lazy } from 'react';
import { render } from 'react-dom';
import { Switch, Route } from 'react-router-dom';
import Providers from './Providers';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import './i18n';

const App = lazy(() => import('./App'));
const Auth = lazy(() => import('containers/Auth'));
const Callback = lazy(() => import('containers/Callback'));

export const history = createBrowserHistory();

render(
  <Providers history={history}>
    <Switch>
      <Route path="/connexion" component={Auth} />
      <Route path="/callback/:kind" component={Callback} />
      <Route component={App} />
    </Switch>
  </Providers>,
  document.getElementById('root'),
);

serviceWorker.unregister();
