import { SERGIC_OFFER } from '../constants';
import { size, spacing } from './utils';

const getBrandPalette = () => {
  switch (SERGIC_OFFER) {
    case 'DIR':
    default:
      return {
        primary: '#006EF2',
        primaryLight: '#006EF2',
        primaryUltraLight: '#deedeb',
        primaryGradientOne: '#006EF2',
        primaryGradientTwo: '#006EF2',
        primaryButtonGradientOne: '#006EF2',
        primaryButtonGradientTwo: '#006EF2',
        primaryButtonIconColor: '#fff',
        primaryButtonTextColor: '#fff',
        primaryGrey: '#5C7592',
        primaryButtonBoxShadowHover: '0 2px 8px 0 #9fb8c7',
        primaryButtonBoxShadow: 'none',
        sidebarGradientOne: '#fff',
        sidebarGradientTwo: '#fff',
        sidebarLinkColor: '#002C60',
        sidebarActiveLinkColor: '#002C60',
        sidebarActiveLinkSvgColor: '#002C60',
        sidebarActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.12)',
        sidebarLinkPadding: '16px',
        sidebarLinkMargin: '0',
        sidebarLinkBorderRadius: '0',
        sidebarSubmenuBackgroundColor: 'rgba(0, 0, 0, 0.12)',
        sidebarMenuActiveLinkColor: '#002C60',
        sidebarMenuActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.08)',
        sidebarMenuActiveLinkInnerBackgroundColor: 'transparent',
        sidebarMenuActiveLinkInnerVerticalPadding: '12px',
        sidebarMenuActiveLinkFontWeight: '400',
        background: '#EAF4F2',
        backgroundLight: '#f4fafe',
        default: '#006EF2',
        medium: '#419890',
        dark: '#002C60',
        vibrant: '#30c0ad',
        light: '#addcd6',
        background_infobox: '#F4F6F6',
        border_infobox: '#BBCCDE',
      };
  }
};

const colors = {
  brand: getBrandPalette(),
  black: {
    default: '#212121',
    secondary: '#707070',
    tertiary: '#9e9e9e',
    quaternary: '#cccccc',
  },
  red: {
    default: '#e22a2a',
    light: 'rgba(215, 38, 61, 0.05)',
    medium: '#d7263d',
    dark: '#bc021a',
  },
  grey: {
    default: '#f6f7f7',
    medium: '#BBCCDE',
    light: '#fafafa',
    veryLighter: '#F4F6F6',
    dark: '#bbb',
  },
  green: {
    default: '#0C8484',
    light: 'rgba(12, 132, 132, 0.05)',
  },
  orange: {
    default: '#ff9819',
    dark: '#e66322',
  },
  yellow: {
    default: '#f2b230',
  },
  blue: {
    default: '#0080c8',
    dark: '#006EF20D',
    light: 'rgba(0, 110, 242, 0.05)',
  },
};

const color = (name, shade = 'default') => {
  return colors[name] && colors[name][shade];
};

const colorsUi = {
  text: color('black'),
  textSecondary: color('black', 'secondary'),
  textLight: color('black', 'tertiary'),
  textLighter: color('black', 'quaternary'),
  textBlueLight: color('blue'),
  background: '#f0f8fa',
  backgroundLight: '#f0f8fa',
  primary: color('brand'),
  primaryVibrant: color('brand', 'vibrant'),
  primaryLight: color('brand', 'light'),
  primaryDark: color('brand', 'dark'),
  disabled: color('grey', 'medium'),
  success: color('green', 'default'),
  backgroundSuccess: color('green', 'light'),
  error: color('red', 'medium'),
  backgroundError: color('red', 'light'),
  warning: color('blue', 'dark'),
  backgroundWarning: color('blue', 'light'),
  separator: 'rgba(0, 0, 0, 0.10)',
  separatorLight: 'rgba(0, 0, 0, 0.05)',
  imageShadow: 'rgba(2, 24, 43, .25)',
  blueShadow: 'rgba(89, 149, 179, .15)',
  lightGrey: color('grey', 'medium'),
  veryLighterGrey: color('grey', 'veryLighter'),
};

const ui = name => colorsUi[name];

export { color, ui };

export const theme = {
  mainHeaderHeight: '72px',
  mainSidebarWidth: '250px',
  secondarySidebarWidth: '420px',

  text: '#002C60',
  textLight: 'rgba(0, 0, 0, 0.6)',
  textLighter: 'rgba(0, 0, 0, 0.38)',
  lightGrey: '#EAF4F2',
  veryLighterGrey: color('grey', 'veryLighter'),
  primaryGradient: `linear-gradient(135deg, ${
    getBrandPalette().primaryGradientOne
  } 0%, ${getBrandPalette().primaryGradientTwo} 100%)`,
  sidebarGradient: `linear-gradient(135deg, ${
    getBrandPalette().sidebarGradientOne
  } 0%, ${getBrandPalette().sidebarGradientTwo} 100%)`,
  disabledBackground: '#EAF4F2',
  disabledBackgroundLight: 'rgba(0, 0, 0, 0.06)',
  disabledText: '#BBCCDE',
  separator: 'rgba(0, 0, 0, 0.12)',
  separatorLight: 'rgba(0, 0, 0, 0.08)',
  success: '#0C8484',
  error: '#D7263D',
  warning: '#006EF2',
  borderRadius: '6px',
  borderRadiusButton: '200px',

  ...getBrandPalette(),

  spacing,
  size,
};

export const careTakerTheme = {
  primary: '#5a52ff',
  primaryButtonGradientOne: '#5a52ff',
  primaryButtonGradientTwo: '#5a52ff',
  primaryButtonBoxShadow: 'hsl(243, 100%, 66%, 0.5)',
};

export const serviceAnytimeTheme = {
  primary: '#003a5e',
  primaryLight: '#009de0',
  text: '#003a5e',
};
