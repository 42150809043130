import { css } from 'styled-components';
import { round } from 'lodash';

export const MAIN_HEADER_HEIGHT = '60px';

export const base = 16;
export const spacing = (input = 1) => `${input * base}px`;

export const size = (input = 0) =>
  `${round(Math.sqrt(Math.sqrt(2)) ** input, 3)}rem`;

export const viewportSizes = {
  desktop: 1366,
  medium: 1100,
  tablet: 800,
  tabletMini: 500,
  all: 0,
};

const mediaQuery = (...query) => (...rules) =>
  css`
    @media ${css(...query)} {
      ${css(...rules)};
    }
  `;

export const media = {
  tabletMini: mediaQuery`(min-width: ${viewportSizes.tabletMini / 16}em)`,
  tablet: mediaQuery`(min-width: ${viewportSizes.tablet / 16}em)`,
  medium: mediaQuery`(min-width: ${viewportSizes.medium / 16}em)`,
  desktop: mediaQuery`(min-width: ${viewportSizes.desktop / 16}em)`,
};

export const addressLabel = ({ streetNumber, street }) => {
  return `${streetNumber ? streetNumber + ',' : ''} ${
    street ? street + ',' : ''
  } `;
};

export const addressValue = node => {
  return {
    city: node?.city,
    complementary: node?.complementary,
    country: node?.country,
    dqeStreetId: node?.dqeStreetId,
    inseeCode: node?.inseeCode,
    street: node?.street,
    streetNumber: node?.streetNumber,
    zipCode: node?.zipCode,
    locality: node?.locality,
  };
};

export const titles = [
  { value: 'MR', label: 'Monsieur' },
  { value: 'MME', label: 'Madame' },
  { value: 'M&ME', label: 'Madame et Monsieur' },
  { value: 'M/ME', label: 'Madame ou Monsieur' },
  { value: 'MRS', label: 'Messieurs' },
  { value: 'MMES', label: 'Mesdames' },
  { value: 'MMM', label: 'Madame, Mademoiselle ou Monsieur' },
  { value: 'MLE', label: 'Mademoiselle' },
  { value: 'SCI', label: 'Société civile immobilière' },
  { value: 'ADM', label: 'Administrations' },
  { value: 'ASS.', label: 'Association' },
  { value: 'ASL', label: 'Association syndicale libre' },
  { value: 'BQE', label: 'Banque' },
  { value: 'CAB', label: 'Cabinet' },
  { value: 'CLUB', label: 'Club' },
  { value: 'CIE', label: 'Compagnie' },
  { value: 'DOCT', label: 'Docteur' },
  { value: 'ETS', label: 'Etablissements' },
  { value: 'EIRL', label: 'Entreprise individuelle à responsabilité limitée' },
  {
    value: 'EURL',
    label: 'Entreprise unipersonnelle à responsabilité limitée',
  },
  { value: 'GIE', label: 'Groupement d’intéret économique' },
  { value: 'GRPE', label: 'Groupe' },
  { value: 'INDI', label: 'Indivision' },
  { value: 'MTRE', label: 'Maître' },
  { value: 'SCP', label: 'Maîtres' },
  { value: 'NOT.', label: 'Notaire' },
  { value: 'SA', label: 'Société anonyme' },
  { value: 'SARL', label: 'Société à responsabilité limitée' },
  { value: 'SAS', label: 'Société anonyme simplifiée' },
  { value: 'SC', label: 'Société civile' },
  { value: 'SCCV', label: 'Société civile construction vente' },
  { value: 'SCM', label: 'Société civile de moyens' },
  { value: 'SCPI', label: 'Société civile promotion immobilière' },
  { value: 'SEM', label: 'Société d’économie mixte' },
  { value: 'SNC', label: 'Société en nom collectif' },
  { value: 'SPE', label: 'Société privée européenne' },
  { value: 'STE', label: 'Société' },
  { value: 'SUCC', label: 'Succession' },
  { value: 'SYND', label: 'Syndicat' },
];
