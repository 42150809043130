import { memo } from 'react';
import Icon from 'react-icon-base';

const RadioCheck = props => (
  <Icon viewBox='0 0 30 30' fill='none' {...props}>
    <rect x='0.989258' width='24' height='24' rx='12' fill='currentColor' />
    <circle cx='12.9893' cy='12' r='4' fill='white' />
  </Icon>
);

export default memo(RadioCheck);
