import Icon from 'react-icon-base';

const Enveloppe = props => (
  <Icon viewBox="0 0 60 60" {...props}>
    <path
      d="M58.526 28.566L28.569 58.522a5.016 5.016 0 01-3.56 1.473 5.019 5.019 0 01-3.56-1.473L2.619 39.693a.999.999 0 111.414-1.414l18.83 18.829a3.039 3.039 0 004.292 0l29.957-29.956a3.04 3.04 0 000-4.293L38.293 4.042a1 1 0 111.414-1.415l18.819 18.818a5.041 5.041 0 010 7.121zM0 35c0-.033.002-.064.005-.096a4.994 4.994 0 011.473-3.473L31.435 1.475A4.995 4.995 0 0134.994 0H35a1 1 0 011 1v30c0 2.757-2.243 5-5 5H1a1 1 0 01-1-1zm2.168-1H31c1.654 0 3-1.346 3-3V2.166a3.017 3.017 0 00-1.151.723L2.892 32.845A2.998 2.998 0 002.168 34zM24 39v14a1 1 0 102 0V39a1 1 0 10-2 0zm29-13a1 1 0 100-2H39a1 1 0 100 2h14zM15 58H1a1 1 0 100 2h14a1 1 0 100-2zM1 54h8a1 1 0 100-2H1a1 1 0 100 2zm0-6h4a1 1 0 100-2H1a1 1 0 100 2z"
      fill="currentcolor"
      fillRule="evenodd"
    />
  </Icon>
);

export default Enveloppe;
