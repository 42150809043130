import Icon from 'react-icon-base';

const IcPiggyBank = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path
        d="M7.325 22.587a.4.4 0 0 0-.49.283l-.088.33-1.546-.414.197-.734a.4.4 0 0 0-.773-.206l-.197.732a.79.79 0 0 0 .08.608.795.795 0 0 0 .486.372l1.546.414a.798.798 0 0 0 .98-.566l.088-.329a.4.4 0 0 0-.283-.49zm12.061-.716a.4.4 0 0 0-.772.207l.189.708-1.546.414-.094-.35a.4.4 0 1 0-.772.207l.094.35a.797.797 0 0 0 .98.566l1.545-.415a.795.795 0 0 0 .565-.98l-.189-.707zM9.201 9.6a.4.4 0 0 0 0 .8h6.401a.4.4 0 1 0 0-.8h-6.4zm12.796 5.693a.399.399 0 0 0-.456.335C20.984 19.26 16.883 22 12 22c-4.157 0-7.823-1.994-9.122-4.96a.4.4 0 0 0-.367-.24H1.4c-.074 0-.161-.014-.242-.18C1.155 16.615.8 15.894.8 14.8c0-1.08.355-1.815.364-1.833.064-.14.135-.167.236-.167h1.112a.4.4 0 0 0 .367-.24c.845-1.93 2.754-3.512 5.237-4.34a.4.4 0 1 0-.253-.759C5.267 8.327 3.239 9.973 2.256 12H1.4c-.433 0-.776.225-.958.621-.018.036-.442.9-.442 2.179 0 1.28.424 2.143.44 2.176.198.402.539.624.96.624h.856c1.51 3.12 5.386 5.2 9.746 5.2 5.272 0 9.713-3.031 10.33-7.051a.4.4 0 0 0-.335-.456zM12.402 6.4c-.44 0-.8-.359-.8-.8a.4.4 0 1 0-.8 0c0 .743.512 1.364 1.2 1.543V7.2a.4.4 0 0 0 .8 0v-.057c.688-.179 1.2-.8 1.2-1.543 0-.882-.718-1.6-1.6-1.6a.801.801 0 0 1 0-1.6.8.8 0 0 1 .8.8.4.4 0 0 0 .8 0c0-.743-.512-1.364-1.2-1.543V1.6a.4.4 0 1 0-.8 0v.057c-.688.179-1.2.8-1.2 1.543 0 .882.717 1.6 1.6 1.6a.8.8 0 0 1 0 1.6zm-3.6-2c0 1.985 1.615 3.6 3.6 3.6s3.6-1.615 3.6-3.6-1.615-3.6-3.6-3.6a3.605 3.605 0 0 0-3.6 3.6zm-.8 0c0-2.426 1.974-4.4 4.4-4.4 2.427 0 4.4 1.974 4.4 4.4s-1.973 4.4-4.4 4.4a4.405 4.405 0 0 1-4.4-4.4zm15.474 7.306a.4.4 0 1 0-.544.587c.003.003.296.29.266.622-.029.315-.347.58-.61.748-.087.056-.175.099-.262.136-.409-2.615-2.446-4.874-5.547-6.107a.4.4 0 1 0-.295.743c2.838 1.129 4.695 3.167 5.054 5.507a2.044 2.044 0 0 1-.917-.278.4.4 0 0 0-.437.67c.038.025.643.413 1.459.413.106 0 .216-.007.328-.022.006 0 .01.003.015.003l.03-.001a.4.4 0 0 0 .135-.038c.28-.058.571-.163.868-.352.601-.383.93-.837.976-1.35.064-.718-.46-1.225-.52-1.28zM6.4 11.2a.8.8 0 1 1-.001 1.602A.8.8 0 0 1 6.4 11.2z"
        fillRule="evenodd"
      />
    </g>
  </Icon>
);

export default IcPiggyBank;
