import { memo } from 'react';
import Icon from 'react-icon-base';

const RadioUnCheck = props => (
  <Icon viewBox='0 0 30 30' fill='none' {...props}>
    <rect x='1.32275' y='0.5' width='23' height='23' rx='11.5' fill='white' />
    <rect x='1.32275' y='0.5' width='23' height='23' rx='11.5' stroke='#BBCCDE' />
  </Icon>
);

export default memo(RadioUnCheck);