import Icon from 'react-icon-base';

const IcInfo = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.37207 21C7.7054 21 6.28874 20.4167 5.12207 19.25C3.9554 18.0833 3.37207 16.6667 3.37207 15V9C3.37207 7.33333 3.9554 5.91667 5.12207 4.75C6.28874 3.58333 7.7054 3 9.37207 3H15.3721C17.0387 3 18.4554 3.58333 19.6221 4.75C20.7887 5.91667 21.3721 7.33333 21.3721 9V15C21.3721 16.6667 20.7887 18.0833 19.6221 19.25C18.4554 20.4167 17.0387 21 15.3721 21H9.37207ZM12.3721 10.25C12.7863 10.25 13.1221 10.5858 13.1221 11V17C13.1221 17.4142 12.7863 17.75 12.3721 17.75C11.9579 17.75 11.6221 17.4142 11.6221 17V11C11.6221 10.5858 11.9579 10.25 12.3721 10.25ZM13.3721 8C13.3721 7.44772 12.9244 7 12.3721 7C11.8198 7 11.3721 7.44772 11.3721 8C11.3721 8.55228 11.8198 9 12.3721 9C12.9244 9 13.3721 8.55228 13.3721 8Z" fill="#006EF2"/>
    </svg>
  </Icon>
);

export default IcInfo;
