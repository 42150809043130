import styled, { css } from 'styled-components';
import { Stack, media } from '@tymate/margaret';

export const OnboardingFormWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const BottomBar = styled(Stack)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  background-color: #ffffff;
  box-shadow: inset 0 1px 0 ${({ theme }) => theme.separator};

  ${({ variant }) =>
    variant === 'withSidebar' &&
    media.tablet`
      left: ${({ theme }) => theme.mainSidebarWidth};
    `}

  ${({ budgetBar }) =>
    budgetBar &&
    css`
      position: initial;
    `};
`;

export const BaseCard = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 1,
  size: 'full',
  padding: 2,
  paddingVertical: 1.25,
})`
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 2px 3px 4px 0px rgba(0, 44, 96, 0.16);
`;